<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7198 9.37039C20.731 9.25138 20.7044 9.12645 20.6301 9.01196L15.6501 1.26334C15.4246 0.91222 14.9091 0.91222 14.6835 1.26334L9.70212 9.01196C9.45837 9.39162 9.73294 9.88889 10.1854 9.88889H20.1468C20.4634 9.88889 20.6931 9.646 20.7198 9.37039Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 3.08333C4.19036 3.08333 4.75 3.64298 4.75 4.33333V19.75H20.1667C20.857 19.75 21.4167 20.3096 21.4167 21C21.4167 21.6904 20.857 22.25 20.1667 22.25H4.5C3.25736 22.25 2.25 21.2426 2.25 20V4.33333C2.25 3.64298 2.80964 3.08333 3.5 3.08333Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1042 6.41819C15.7937 6.38371 16.3806 6.91471 16.4151 7.6042C16.707 13.4424 14.953 16.9724 12.2851 18.89C9.69838 20.7492 6.54663 20.8725 4.43433 20.5707C3.75092 20.4731 3.27604 19.8399 3.37367 19.1565C3.47131 18.4731 4.10447 17.9982 4.78789 18.0959C6.56448 18.3497 8.96829 18.1952 10.826 16.8599C12.6025 15.5831 14.1819 13.002 13.9182 7.72905C13.8838 7.03955 14.4148 6.45266 15.1042 6.41819Z"
    />
  </svg>
</template>
